html,
body {
  min-width: 350px;
  height: 100%;
}

body,
h1,
h2,
a,
p,
input,
option,
textarea,
.lt {
  font-family: 'Open Sans', 'sans-serif';
  font-weight: 300;
  text-decoration: none;
  outline: none;
}

h2 {
  font-family: 'Open Sans', 'sans-serif';
  font-weight: 300;
}

body {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  background: #13161b;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  display: none;
}

img {
  height: auto;
}

.logo {
  max-height: 1.3em;
  padding-right: 0.3em;
  vertical-align: bottom;
  width: auto;
}

.graphic {
  max-width: 100%;
  height: auto;
}
@media (max-device-width: 800px) {
  .graphic {
    max-height: none;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .graphic {
    max-height: none;
    width: 100%;
    max-width: 100%;
  }
}

.contain {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
@media (max-device-width: 800px) {
  .contain {
    width: 100%;
    flex-direction: column;
  }
}
@media (max-width: 650px) {
  .contain {
    width: 100%;
    flex-direction: column;
  }
}
.contain.vertical {
  flex-direction: column;
  justify-content: flex-start;
}
.contain.vertical.center {
  justify-content: center;
}
.contain.center {
  align-items: center;
}
@media (max-device-width: 800px) {
  .contain.static {
    flex-direction: row;
  }
}
@media (max-width: 700px) {
  .contain.static {
    flex-direction: row;
  }
}
.contain.content {
  width: 100%;
  max-width: 100%;
  height: 100vh;
}
@media (max-device-width: 800px) {
  .contain.content {
    height: auto;
    min-height: 100vh;
    width: 100%;
    max-width: none;
  }
}
.contain.sidebar {
  width: 40em;
}
@media (max-device-width: 800px) {
  .contain.sidebar {
    width: 100%;
  }
}

.fullPanel {
  overflow-y: scroll;
  flex: 1;
}
@media (max-device-width: 800px) {
  .fullPanel {
    overflow-y: visible;
  }
}

nav {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  color: #000000;
  background: #ffffff;
  width: 100%;
}
nav .lt.space {
  background: none;
}
nav .lt.btn {
  background: none;
}
nav .lt.btn:hover,
nav .lt.btn:focus {
  background: #d5dae1;
}
nav.primary {
  color: #ffffff;
  background: #13161b;
}
nav.primary .lit {
  background: #bfbfbf;
}
nav.primary .lt.btn:hover,
nav.primary .lt.btn:focus {
  background: #3d4757;
}
nav.blank {
  background: none;
}

footer {
  width: 100%;
  background: #13161b;
}
footer .lt.space {
  background: inherit;
}
footer .lt.btn {
  color: #ffffff;
  background: #13161b;
}
footer .lt.btn:hover,
footer .lt.btn:focus {
  background: #3d4757;
}

.lt {
  flex: 0 1 auto;
  background: none;
  text-align: center;
  color: #000000;
  background: #ffffff;
}
.panel {
  margin: 0 1px 2px;
}
@media (max-device-width: 800px) {
  .panel {
    max-width: none;
  }
}
@media (max-width: 700px) {
  .lt.panel {
    max-width: none;
    margin: 0 0 2px 0;
  }
}
.lt.text {
  padding: 0.6em 2em;
}
.lt.btn {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -html-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  font-size: 1em;
}
@media (max-device-width: 800px) {
  .lt.btn {
    font-size: 1.05em;
  }
}
.lt.btn.solid {
  overflow: visible;
}
.lt.btn:hover,
.lt.btn:focus {
  background: #d5dae1;
}
.lt.btn:hover.group,
.lt.btn:focus.group {
  background: #99a4b7;
}
.lt.btn:hover.static,
.lt.btn:focus.static {
  background: inherit;
}
.lt.select {
  background: #ffffff;
}
.lt.select:hover,
.lt.select:focus {
  background: #66a4cc;
}
.lt.selectDark {
  background: #ffffff;
}
.lt.selectDark:hover,
.lt.selectDark:focus {
  background: #0050cc;
}
.lt.submit {
  background: #253d59;
  color: #ffffff;
}
.lt.submit:hover,
.lt.submit:focus {
  background: #2b5586;
}
.lt.space {
  flex: 10 0 auto;
}
.lt.blank {
  background: none;
}
.lt.blur {
  color: #ffffff;
  background: #ffffff;
  /*background: rgba(255, 255, 255, 0.97);*/
  box-shadow: inset 0 -3px 0 #0064ff;
}

.overlay {
  position: relative;
}

.overlayItem {
  position: absolute;
  z-index: 5;
  background: #ffffff;
  opacity: 0;
}

.overlayItem:hover {
  opacity: 1;
}

.expand {
  flex: 1 1 auto;
}

.justify {
  text-align: justify;
}

.aRight {
  text-align: right;
}

.aLeft {
  text-align: left;
}

.centerText {
  text-align: center;
}

.wrap {
  white-space: normal;
  text-align: left;
}

.leftMargin {
  margin-left: 1em;
}

.rightMargin {
  margin-right: 1em;
}
@media (max-device-width: 800px) {
  .rightMargin {
    margin-right: 0;
  }
}

.topMargin {
  margin-top: 1em;
}

.bottomMargin {
  margin-bottom: 1em;
}

.w1 {
  width: 100%;
  max-width: 100%;
}

.w2 {
  width: 50%;
  max-width: 100%;
}
@media (max-device-width: 800px) {
  .w2 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .w2 {
    width: 100%;
    max-width: none;
  }
}

.w3 {
  width: 33.33333%;
  max-width: 100%;
}

.w4 {
  width: 25%;
  max-width: 100%;
}

.w5 {
  width: 20%;
  max-width: 100%;
}

.em5 {
  width: 5em;
}
@media (max-device-width: 800px) {
  .em5 {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .em5 {
    width: 100%;
  }
}

.em10 {
  width: 10em;
  max-width: 10em;
}
@media (max-device-width: 800px) {
  .em10 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em10 {
    width: 100%;
    max-width: none;
  }
}

.em15 {
  width: 15em;
  max-width: 15em;
}
@media (max-device-width: 800px) {
  .em15 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em15 {
    width: 100%;
    max-width: none;
  }
}

.em20 {
  width: 20em;
  max-width: 20em;
}
@media (max-device-width: 800px) {
  .em20 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em20 {
    width: 100%;
    max-width: none;
  }
}

.em30 {
  width: 30em;
  max-width: 30em;
}
@media (max-device-width: 800px) {
  .em30 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em30 {
    width: 100%;
    max-width: none;
  }
}

.em40 {
  width: 40em;
  max-width: 40em;
}
@media (max-device-width: 800px) {
  .em40 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em40 {
    width: 100%;
    max-width: none;
  }
}

.em50 {
  width: 50em;
  max-width: 50em;
}
@media (max-device-width: 800px) {
  .em50 {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 700px) {
  .em50 {
    width: 100%;
    max-width: none;
  }
}

a {
  color: #000000;
}

h1 {
  margin: 0;
  padding: 0.6em 0;
  font-size: 1.6em;
  color: #000000;
}
@media (max-device-width: 800px) {
  h1 {
    font-size: 2em;
  }
}

h2 {
  margin: 0;
  padding: 0.6em 0;
  font-size: 1.2em;
  color: #000000;
}
@media (max-device-width: 800px) {
  h2 {
    font-size: 1.5em;
  }
}

p {
  margin: 0;
  padding: 0.6em 0;
  font-size: 1em;
  color: #000000;
}
p a {
  color: #3383ff;
}
p a:visited {
  color: #3383ff;
}
p a:hover {
  color: #0050cc;
}
@media (max-device-width: 800px) {
  p {
    font-size: 1.1em;
  }
}

.accentLight {
  background: #3383ff;
}
.accentLight:hover {
  background: #0064ff !important;
}

.accentDark {
  background: #253d59;
}

.textLight {
  color: #ffffff;
}

.textDark {
  color: #000000;
}

.group {
  background: #b7bfcc;
}

.mobile {
  display: none;
}
@media (max-device-width: 800px) {
  .mobile {
    display: block;
  }
}

.nonMobile {
  display: block;
}
@media (max-device-width: 800px) {
  .nonMobile {
    display: none;
  }
}

.invisible {
  display: none;
}

input,
textarea,
select {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  font-size: 1em;
  padding: 0.6em;
}
@media (max-device-width: 800px) {
  input,
  textarea,
  select {
    font-size: 1.05em;
  }
}

input[type='checkbox'] {
  -moz-appearance: checkbox;
  -webkit-appearance: checkbox;
  appearance: checkbox;
}
@media (max-device-width: 800px) {
  input[type='checkbox'] {
    height: 3em;
    widows: 3em;
  }
}

input[type='text'],
input[type='password'] {
  text-align: left;
  background: #ffffff;
}

textarea {
  resize: none;
  min-height: 6em;
}

.graph {
  height: 50em;
}

.leadgraph {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

.titleText {
  font-weight: 700;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

button {
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
}

/*# sourceMappingURL=lattice.css.map */
